<template>
  <div class="PageInfo">
    <p class="w-full text-center py-5 text-primary-1-40 text-sm uppercase border-b border-t border-primary-1-10">
      {{ t('showingXoutOf', { count: currentCount, total: totalCount }) }}
    </p>

    <LoadingDots v-if="isFetching" class="mt-5" />

    <AppButton
      v-if="currentCount < totalCount"
      type="button"
      :disabled="isFetching"
      variant="outline"
      position="center"
      class="LoadMore text-center mt-5 w-full lg:w-fit"
      @click="onNext"
    >
      <InfiniteScrollObserver @intersect="onNext" />
      <h4 class="font-bold uppercase">{{ t('loadMore') }}</h4>
    </AppButton>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n({
  useScope: 'local',
});

const props = defineProps({
  currentCount: {
    type: Number,
    required: true,
  },
  totalCount: {
    type: Number,
    required: true,
  },
  isFetching: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['next']);

function onNext() {
  if (props.currentCount < props.totalCount) {
    emit('next');
  }
}
</script>

<style lang="postcss" scoped>
.PageInfo {
  @apply w-full flex flex-col items-center;

  .LoadMore {
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "showingXoutOf": " {count} / {total} products",
    "loadMore": "Load More Products"
  },
  "ar": {
    "showingXoutOf": "{count} من {total} منتجات",
    "loadMore": " تحميل المزيد من المنتجات"
  }
}
</i18n>
